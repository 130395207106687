export interface TiendaDataI {
  id: number;
  nombre: string;
  direccion: string;
  ubicacion: string;
  foto: string;
  celular: string;
}

export class Tienda implements TiendaDataI {
  id: number;
  nombre: string;
  direccion: string;
  ubicacion: string;
  foto: string;
  celular: string;
  constructor(data: TiendaDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.direccion = data.direccion;
    this.ubicacion = data.ubicacion;
    this.foto = data.foto;
    this.celular = data.celular;
  }
}
