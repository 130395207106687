import "../../styles/widgets/actividaddetalle.card.css";
import { Actividad } from "../../models/actividad";
import { Fragment } from "react";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function ActividadDetalleCard({
  foto,
  actividad,
}: {
  foto: string;
  actividad: Actividad;
}) {
  const [load, setLoad] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="actividad-detalle-card">
        {!load && <Skeleton.Image />}
        <img
          src={`${Global.URL_FOTOS_ACTIVIDADES}${foto}`}
          alt={actividad.descripcion}
          title={actividad.descripcion}
          className={load ? "visible foto-actividad-detalle-card" : "hide"}
          onLoad={() => {
            setLoad(true);
          }}
        />
      </div>
    </Fragment>
  );
}

export default ActividadDetalleCard;
