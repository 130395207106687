import { APIResponse } from "../models/api_response";
import { API } from "./api";

export class APIEmail extends API {
  insertarCupon(nombres: string, email: string, celular: string, documento: string) {
    throw new Error("Method not implemented.");
  }
  constructor() {
    super("/newsletter/");
  }

  async insertarEmail(email: string): Promise<APIResponse<any>> {
    const result: APIResponse<any[]> = await this.get(`"${email}"`, { auth: false });
    if (result.hasException) {
      return result as any;
    }
    return result as any;
  }
}
