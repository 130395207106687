import "../../styles/widgets/tienda.card.css";
import { Fragment } from "react";
import { Skeleton } from "antd";
import { Cupon } from "../../models/cupon";
import { useState } from "react";
import Global from "../../global/global";

function CuponCard({ cupon }: { cupon: Cupon }) {
  const [load, setLoad] = useState<boolean>(false);
  
  return (
    <Fragment>
      {!load && <Skeleton.Image />}
      <img
        alt={cupon.nombre}
        title={cupon.nombre}
        src={`${Global.URL_FOTOS_CUPON}${cupon.url}`}
        className={load ? "visible" : "hide"}
        onLoad={() => {
          setLoad(true);
        }}
      />
    </Fragment>
  );
}

export default CuponCard;
