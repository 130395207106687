import "../../styles/pages/actividades.page.css";
import { Actividad } from "../../models/actividad";
import { APIActividades } from "../../api/api_actividades";
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ActividadCard from "../widgets/actividad.card";
import ActividadesContext from "../../providers/actividades-provider";
import AppBarComponent from "../components/appbar.component";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import LoadPage from "./load.page";
import NoData from "../widgets/nodata.card";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";

function ActividadesPage() {
  const [mounted, setMounted] = useState<any>(null);
  const apiActividades = useContext<APIActividades>(ActividadesContext);
  const [actividades, setActividades] = useState<Actividad[]>([]);

  useEffect(() => {
    setMounted(true);
    apiActividades.listarActividades().then((response) => {
      if (response.hasData) {
        setActividades(response.data);
      }
    });
    return () => {
      setMounted(false);
    };
  }, [apiActividades]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-actividades-page">ACTIVIDADES</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {actividades.length > 0 ? (
              <Fragment>
                <Row>
                  {actividades.map((actividad: Actividad, _index: number) => (
                    <ActividadCard
                      actividad={actividad}
                      key={`actividad-${_index}`}
                    />
                  ))}
                </Row>
              </Fragment>
            ) : (
              <NoData />
            )}
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent actividades />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default ActividadesPage;
