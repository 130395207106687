import "../../styles/components/footer.component.css";
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";
import { FacebookOutlined } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { YouTube } from "@mui/icons-material";

function FooterComponent({
  actividades,
  bolsa,
  facturacion,
  libro,
  opinion,
  postventa,
  responsabilidad,
}: {
  actividades?: boolean;
  bolsa?: boolean;
  facturacion?: boolean;
  libro?: boolean;
  opinion?: boolean;
  postventa?: boolean;
  responsabilidad?: boolean;
}) {
  return (
    <Fragment>
      <Row className="footer">
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <h3>
            <a
              href="/bolsa"
              className={bolsa ? "item-active-footer" : "item-footer"}
            >
              Bolsa de trabajo
            </a>
          </h3>
          <h3>
            <a
              href="/responsabilidad"
              className={responsabilidad ? "item-active-footer" : "item-footer"}
            >
              Responsabilidad Social
            </a>
          </h3>
          <h3>
            <a
              href="/postventa"
              className={postventa ? "item-active-footer" : "item-footer"}
            >
              Post Venta
            </a>
          </h3>
          <h3>
            <a
              href="/actividades"
              className={actividades ? "item-active-footer" : "item-footer"}
            >
              Actividades
            </a>
          </h3>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <h3>
            Email: <br />
            postventa@3aamseq.com.pe
          </h3>
          <h3>
            Oficina: <br />
            Urb. La Merced, Trujillo, La Libertad, Perú
          </h3>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <h3>S&iacute;guenos en:</h3>
          <a
            href="https://www.facebook.com/3AAMSEQPERU"
            rel="noreferrer"
            target="_blank"
          >
            <FacebookOutlined className="icon-siguenos-footer" />
          </a>
          <a
            href="https://www.instagram.com/3aamseqperu/"
            rel="noreferrer"
            target="_blank"
          >
            <Instagram className="icon-siguenos-footer" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCO43nSLPFmLIlEZQwHK7B3w"
            rel="noreferrer"
            target="_blank"
          >
            <YouTube className="icon-siguenos-footer" />
          </a>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <h3>
            <a
              href="/opinion"
              className={opinion ? "item-active-footer" : "item-footer"}
            >
              Danos tu opini&oacute;n
            </a>
          </h3>
          <h3>
            <a
              href="https://sfe.bizlinks.com.pe/sfeperu/public/loginAnonimo.jsf"
              rel="noreferrer"
              target="_blank"
              className={facturacion ? "item-active-footer" : "item-footer"}
            >
              Facturaci&oacute;n Electr&oacute;nica
            </a>
          </h3>
          <h3>
            <a
              href="/libroreclamaciones"
              className={libro ? "item-active-footer" : "item-footer"}
            >
              Libro de reclamaciones
            </a>
          </h3>
        </Col>
      </Row>
    </Fragment>
  );
}

export default FooterComponent;
