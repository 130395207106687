export interface PaginationDataI {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;
}

export interface PaginationI {
  items: any[];
  meta: PaginationDataI;
}

export class Pagination<T> {
  items: T[];
  meta: PaginationData;

  constructor(items: T[], meta: PaginationDataI) {
    this.items = items;
    this.meta = new PaginationData(meta);
  }
}

class PaginationData {
  totalItems: number;
  itemCount: number;
  itemsPerPage: number;
  totalPages: number;
  currentPage: number;

  constructor(meta: PaginationDataI) {
    this.totalItems = meta.totalItems;
    this.itemCount = meta.itemCount;
    this.itemsPerPage = meta.itemsPerPage;
    this.totalPages = meta.totalPages;
    this.currentPage = meta.currentPage;
  }

  get nextPage() {
    return this.currentPage + 1;
  }
}
