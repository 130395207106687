import { BrowserRouter } from "react-router-dom";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import ActividadesPage from "./actividades.page";
import BolsaTrabajoPage from "./bolsa.page";
import CatalogoPage from "./catalogo.page";
import InicioPage from "./inicio.page";
import NosotrosPage from "./nosotros.page";
import NotFoundPage from "./notfound.page";
import OpinionPage from "./opinion.page";
import PostVentaPage from "./postventa.page";
import ProductosPage from "./productos.page";
import ProyectosPage from "./proyectos.page";
import LibroPage from "./proyectos.page copy";
import ResponsabilidadPage from "./responsabilidad.page";
import UbicanosPage from "./ubicanos.page";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InicioPage />} />
        <Route path="/nosotros" element={<NosotrosPage />} />
        <Route path="/ubicanos" element={<UbicanosPage />} />
        <Route path="/catalogo" element={<CatalogoPage />} />
        <Route path="/productos" element={<ProductosPage />} />
        <Route path="/proyectos" element={<ProyectosPage />} />
        <Route path="/bolsa" element={<BolsaTrabajoPage />} />
        <Route path="/responsabilidad" element={<ResponsabilidadPage />} />
        <Route path="/actividades" element={<ActividadesPage />} />
        <Route path="/postventa" element={<PostVentaPage />} />
        <Route path="/opinion" element={<OpinionPage />} />
        <Route path="/libroreclamaciones" element={<LibroPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
