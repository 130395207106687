import "../../styles/pages/postventa.page.css";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import correo from "../../assets/icons/correo.svg";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import Global from "../../global/global";
import LoadPage from "./load.page";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";

function PostVentaPage() {
  const [mounted, setMounted] = useState<any>(null);
  const [formPostVenta] = Form.useForm();
  const { TextArea } = Input;
  const [spin, setSpin] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  const onFinish = (body: any) => {
    setSpin(true);
    const SibApiV3Sdk: any = require("sib-api-v3-typescript");
    let apiInstance: any = new SibApiV3Sdk.TransactionalEmailsApi();
    let apiKey: any = apiInstance.authentications["apiKey"];
    apiKey.apiKey = Global.API_KEY_SENDINBLUE;
    let sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();
    sendSmtpEmail.subject = "POST VENTA";
    sendSmtpEmail.htmlContent = `
    <!DOCTYPE html>
    <html lang="es">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>SOLICITUD DE TRABAJO - 3A AMSEQ</title>
        <style>
          body {
            font-family: sans-serif;
          }
    
          .content {
            text-align: center;
            padding: 20px;
            border-radius: 10px;
          }
    
          .content h3 {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(244, 220, 43, 1);
            padding: 20px;
            border-radius: 10px;
          }
    
          .content .mail {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(255, 255, 255, 1);
            padding: 20px;
            border-radius: 10px;
          }
        </style>
      </head>
      <body>
        <div class="content">
          <h3>3A AMSEQ - POST VENTA</h3>
          <div class="mail">
            <p>Nombres: <b>${body["nombre"]}</b></p>
            <p>Empresa: <b>${body["empresa"]}</b></p>
            <p>Email: <b>${body["email"]}</b></p>
            <p>Celular: <b>${body["celular"]}</b></p>
            <p>Mensaje: <b>${body["mensaje"]}</b></p>
          </div>
        </div>
      </body>
    </html>
    `;
    sendSmtpEmail.sender = {
      name: body["nombre"],
      email: body["email"],
    };
    sendSmtpEmail.to = [
      {
        email: "postventa@3aamseq.com.pe",
        name: "POST VENTA 3AAMSEQ",
      },
    ];

    sendSmtpEmail.replyTo = {
      email: body["email"],
      name: body["nombre"],
    };

    apiInstance.sendTransacEmail(sendSmtpEmail).then(
      function (data: any) {
        Modal.success({
          title: "3AAMSEQ",
          content: "Muchas gracias por contactarnos",
        });
        formPostVenta.resetFields();
        setSpin(false);
      },
      function (error: any) {
        Modal.error({
          title: "3AAMSEQ",
          content: "Ocurrió un error, inténtalo más tarde",
        });
        setSpin(false);
      }
    );
  };

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row className="postventa-page">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-postventa-page">POST VENTA</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="subtitulo-postventa-page">CONT&Aacute;CTANOS</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <p className="texto-postventa-page">
              Completa el formulario y pronto nos pondremos en contacto contigo.
              Recuerda que puedes enviarnos un correo a{" "}
              <b>postventa@3aamseq.com.pe</b>, tambi&eacute;n puedes llamarnos o
              enviarnos un mensaje por WhatsApp al n&uacute;mero{" "}
              {/* <b><a href="https://api.whatsapp.com/send?phone=51933515889" target="_blank" rel="noreferrer">933 515 889</a></b> */}
              <b>933515889</b>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="formPostVenta"
              onFinish={onFinish}
              form={formPostVenta}
              className="form-postventa-page"
              layout="inline"
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Nombre y Apellido</h3>}
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingrese sus nombres" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Empresa o Raz&oacute;n Social</h3>}
                  name="empresa"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu razón social o el nombre de tu empresa" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Email</h3>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Tel&eacute;fono o Celular</h3>}
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                      //   type: "number",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu telefono o celular" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Mensaje</h3>}
                  name="mensaje"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Ingresa tu mensaje"
                    showCount
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item>
                  {spin ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<img src={correo} alt="Enviar" width={25} />}
                    >
                      &nbsp; Enviar
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent postventa />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default PostVentaPage;
