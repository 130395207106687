import "../../styles/widgets/tienda.card.css";
import { Card } from "antd";
import { Fragment } from "react";
import { RoomOutlined } from "@mui/icons-material";
import { PhoneInTalk } from "@mui/icons-material";
import { Skeleton } from "antd";
import { Tienda } from "../../models/tienda";
import { useState } from "react";
import Global from "../../global/global";

function TiendaCard({ tienda }: { tienda: Tienda }) {
  const [load, setLoad] = useState<boolean>(false);
  const { Meta } = Card;

  return (
    <Card
      key={`card-tienda-${tienda.id}`}
      className="tienda-card"
      cover={
        <Fragment>
          {!load && <Skeleton.Image />}
          <img
            alt={tienda.nombre}
            title={tienda.nombre}
            src={`${Global.URL_FOTOS_TIENDAS}${tienda.foto}`}
            className={load ? "visible" : "hide"}
            onLoad={() => {
              setLoad(true);
            }}
          />
        </Fragment>
      }
    >
      <Meta
        title={tienda.nombre}
        description={
          <Fragment>
            <a href={tienda.ubicacion} target="_blank" rel="noreferrer">
              <RoomOutlined /> {tienda.direccion}
            </a>
            {tienda.celular ? (
              <p>
                <a
                  href={`https://api.whatsapp.com/send?phone=51${tienda.celular}&text=Hola, deseo cotizar materiales...`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PhoneInTalk /> {tienda.celular}
                </a>
              </p>
            ) : (
              ""
            )}
          </Fragment>
        }
      />
    </Card>
  );
}

export default TiendaCard;
