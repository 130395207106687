import "../../styles/pages/inicio.page.css";
import { APIBanners } from "../../api/api_banners";
import { APIMarcas } from "../../api/api_marcas";
import { APIPromociones } from "../../api/api_promociones";
import { Banner } from "../../models/banner";
import { Carousel as AntDCarousel } from "antd";
import { Col } from "antd";
import { Fragment } from "react";
import { Marca } from "../../models/marca";
import { Promocion } from "../../models/promocion";
import { Row } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import BannersContext from "../../providers/banners-provider";
import Carousel from "react-elastic-carousel";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import LoadPage from "./load.page";
import MarcaCard from "../widgets/marca.card";
import MarcasContext from "../../providers/marcas-provider";
import PromocionCard from "../widgets/promocion.card";
import PromocionesContext from "../../providers/promociones-provider";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";
import BannerCard from "../widgets/banner.card";
import PopupPage from "./popup.page";

function InicioPage() {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [marcas, setMarcas] = useState<Marca[]>([]);
  const [mounted, setMounted] = useState<any>(null);
  const [promociones, setPromociones] = useState<Promocion[]>([]);
  const apiBanners = useContext<APIBanners>(BannersContext);
  const apiMarcas = useContext<APIMarcas>(MarcasContext);
  const apiPromociones = useContext<APIPromociones>(PromocionesContext);

  useEffect(() => {
    setMounted(true);

    apiBanners.listarBanners("Inicio").then((response) => {
      if (response.hasData) {
        setBanners(response.data);
      }
    });

    apiMarcas.listarMarcas().then((response) => {
      if (response.hasData) {
        setMarcas(response.data);
      }
    });

    apiPromociones.listarPromociones().then((response) => {
      if (response.hasData) {
        setPromociones(response.data);
      }
    });

    return () => {
      setMounted(false);
    };
  }, [apiBanners, apiMarcas, apiPromociones]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <PopupPage />
      <AppBarComponent inicio />
      <SubAppBarComponent onSearchProductos={() => { }} />
      <div className="inicio-page">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-carousel-inicio-page"
          >
            <AntDCarousel effect="scrollx">
              {banners.map((banner: Banner, _index: number) => (
                <BannerCard banner={banner} key={`banner-inicio-${_index}`} />
              ))}
            </AntDCarousel>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="col-marcas-inicio-page"
          >
            <Carousel
              itemsToShow={4}
              isRTL={false}
              pagination={false}
              enableMouseSwipe={false}
            >
              {marcas.map((marca: Marca, _index: number) => (
                <MarcaCard marca={marca} key={`marca-${marca.id}`} />
              ))}
            </Carousel>
          </Col>
          {
            promociones.length > 0 ? <Fragment><Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <h1 className="titulo-inicio-page">NUESTRAS PROMOCIONES</h1>
            </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Row>
                  {promociones.map((promocion: Promocion, _index: number) => (
                    <Col
                      xs={24}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      key={`promocion-${promocion.id}`}
                    >
                      <PromocionCard promocion={promocion} />
                    </Col>
                  ))}
                </Row>
              </Col></Fragment> : ""
          }
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default InicioPage;
