import "../../styles/widgets/promocion.detalle.card.css";
import { Col } from "antd";
import { Fragment } from "react";
import { Promocion } from "../../models/promocion";
import { Row } from "antd";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function PromocionDetalleCard({ promocion }: { promocion: Promocion }) {
  const [load, setLaod] = useState<boolean>(false);
  return (
    <Fragment>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12} className="col-promocion-detalle-card">
          {!load && <Skeleton.Image />}
          <img
            src={`${Global.URL_FOTOS_PROMOCIONES}${promocion.imagen}`}
            alt="PROMOCIONES 3AAMSEQ"
            title="PROMOCIONES 3AAMSEQ"
            onLoad={() => {
              setLaod(true);
            }}
            className={load ? "visible imagen-promocion-detalle-card" : "hide"}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          {promocion.descripcion
            ?.split("<br>")
            .map((detalle: string, _index: number) =>
              _index === 0 ? (
                <h1
                  className="titulo-promocion-detalle-card"
                  key={`detalle-promocion-${_index}`}
                >
                  {detalle}
                </h1>
              ) : (
                <p
                  className="descripcion-promocion-detalle-card"
                  key={`detalle-promocion-${_index}`}
                >
                  {detalle}
                </p>
              )
            )}
        </Col>
      </Row>
    </Fragment>
  );
}

export default PromocionDetalleCard;
