import "../../styles/widgets/promocion.card.css";
import { ArrowRightOutlined } from "@mui/icons-material";
import { Button } from "antd";
import { Col } from "antd";
import { Fragment } from "react";
import { Modal } from "antd";
import { Promocion } from "../../models/promocion";
import { Row } from "antd";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";
import PromocionDetalleCard from "./promocion.detalle.card";

function PromocionCard({ promocion }: { promocion: Promocion }) {
  const [load, setLaod] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  return (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-promocion-card"
        >
          {!load && <Skeleton.Image />}
          <img
            src={`${Global.URL_FOTOS_PROMOCIONES}${promocion.imagen}`}
            alt="PROMOCIONES 3AAMSEQ"
            title="PROMOCIONES 3AAMSEQ"
            onLoad={() => {
              setLaod(true);
            }}
            className={load ? "visible imagen-promocion-card" : "hide"}
            onClick={() => {
              setModal(true);
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Button
            className="ver-mas-promocion-card"
            onClick={() => {
              setModal(true);
            }}
          >
            VER M&Aacute;S <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
      <Modal
        // title="DETALLE DE LA PROMOCIÓN"
        visible={modal}
        footer={[]}
        width={800}
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => {
          setModal(false);
        }}
        centered
      >
        <PromocionDetalleCard promocion={promocion} />
      </Modal>
    </Fragment>
  );
}

export default PromocionCard;
