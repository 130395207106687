import "../../styles/pages/bolsa.page.css";
import { APIBanners } from "../../api/api_banners";
import { Banner } from "../../models/banner";
import { Button } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { Skeleton } from "antd";
import { Spin } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import BannersContext from "../../providers/banners-provider";
import correo from "../../assets/icons/correo.svg";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import Global from "../../global/global";
import LoadPage from "./load.page";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";

function BolsaTrabajoPage() {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState("Seleccionar archivo PDF");
  const [formBolsa] = Form.useForm();
  const [load, setLoad] = useState<boolean>(false);
  const [mounted, setMounted] = useState<any>(null);
  const [spin, setSpin] = useState<boolean>(false);
  const apiBanners = useContext<APIBanners>(BannersContext);

  useEffect(() => {
    setMounted(true);
    apiBanners.listarBanners("Bolsa").then((response) => {
      if (response.hasData) {
        setBanners(response.data);
      }
    });
    return () => {
      setMounted(false);
    };
  }, [apiBanners]);

  const sendMail = (body: any, pdfUrl: string) => {
    const SibApiV3Sdk = require("sib-api-v3-typescript");
    let apiInstance = new SibApiV3Sdk.TransactionalEmailsApi();
    let apiKey = apiInstance.authentications["apiKey"];
    apiKey.apiKey = Global.API_KEY_SENDINBLUE;
    let sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();
    let sendSmtpAttachment = new SibApiV3Sdk.SendSmtpEmailAttachment();
    sendSmtpEmail.subject = "SOLICITUD DE TRABAJO";
    sendSmtpEmail.htmlContent = `
    <!DOCTYPE html>
    <html lang="es">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>SOLICITUD DE TRABAJO - 3A AMSEQ</title>
        <style>
          body {
            font-family: sans-serif;
          }
    
          .content {
            text-align: center;
            padding: 20px;
            border-radius: 10px;
          }
    
          .content h3 {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(244, 220, 43, 1);
            padding: 20px;
            border-radius: 10px;
          }
    
          .content .mail {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(255, 255, 255, 1);
            padding: 20px;
            border-radius: 10px;
          }
        </style>
      </head>
      <body>
        <div class="content">
          <h3>3A AMSEQ - SOLICITUD DE TRABAJO</h3>
          <div class="mail">
            <p>Nombres: <b>${body["nombres"]}</b></p>
            <p>Apellidos: <b>${body["apellidos"]}</b></p>
            <p>Área: <b>${body["area"]}</b></p>
            <p>Ciudad: <b>${body["ciudad"]}</b></p>
            <p>Email: <b>${body["email"]}</b></p>
          </div>
        </div>
      </body>
    </html>
    `;
    sendSmtpAttachment.name = fileName;
    sendSmtpAttachment.url = pdfUrl;
    sendSmtpEmail.attachment = [sendSmtpAttachment];

    sendSmtpEmail.sender = {
      name: `${body["nombres"]} ${body["apellidos"]}`,
      email: body["email"],
    };
    sendSmtpEmail.to = [
      {
        email: "kzapata@3aamseq.com.pe",
        name: "KARINA ZAPATA BARTOLA MEDINA",
      },
    ];
    sendSmtpEmail.cc = [
      {
        email: "dvalverde@3aamseq.com.pe",
        name: "DANY LIZBETH VALVERDE TANTAJULCA",
      },
    ];

    sendSmtpEmail.replyTo = {
      email: body["email"],
      name: body["nombres"],
    };

    apiInstance.sendTransacEmail(sendSmtpEmail).then(
      function (data: any) {
        Modal.success({
          title: "3AAMSEQ",
          content:
            "Muchas gracias por querer ser parte de nuestra familia, pronto nos contactaremos contigo",
        });
        formBolsa.resetFields();
        setFileName("Seleccionar archivo PDF");
        setSpin(false);
      },
      function (error: any) {
        Modal.error({
          title: "3AAMSEQ",
          content: "Ocurrió un error, inténtalo más tarde",
        });
        setSpin(false);
      }
    );
  };

  const onFinish = (body: any) => {
    setSpin(true);
    var pdfUrl = "";
    var formData = new FormData();
    formData.append("file", file);
    var xhttp = new XMLHttpRequest();
    xhttp.open(
      "POST",
      "https://www.3aamseq.com.pe/cv3aamseq/api/upload.php",
      true
    );

    xhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        var response = JSON.parse(this.responseText);
        if (response.success === 1) {
          pdfUrl = `${Global.URL_PDF}/${response.message}`;
          sendMail(body, pdfUrl);
        } else {
          Modal.error({
            title: "3AAMSEQ",
            content: "Ocurrió un error al cargar tu CV, inténtalo más tarde",
          });
          setSpin(false);
        }
      }
    };
    xhttp.send(formData);
  };

  const onChangeFile = async (values: any) => {
    setFileName(values.target.files[0].name);
    setFile(values.target.files[0]);
  };

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row className="bolsa-page">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-bolsa-page">BOLSA DE TRABAJO</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="subtitulo-bolsa-page">¡&Uacute;nete al equipo!</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            {banners.length > 0 ? (
              <Fragment>
                {!load && <Skeleton.Image />}
                <img
                  src={`${Global.URL_FOTOS_BANNERS}${banners[0].url}`}
                  alt="3A AMSEQ"
                  title="BOLSA DE TRABAJO"
                  className={load ? "visible banner-bolsa-page" : "hide"}
                  onLoad={() => {
                    setLoad(true);
                  }}
                />
              </Fragment>
            ) : (
              ""
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="formBolsa"
              onFinish={onFinish}
              form={formBolsa}
              className="form-bolsa-page"
              layout="horizontal"
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>Nombres</h3>}
                    name="nombres"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                      },
                    ]}
                  >
                    <Input placeholder="Ingrese sus nombres" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>Apellidos</h3>}
                    name="apellidos"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                      },
                    ]}
                  >
                    <Input placeholder="Ingrese sus apellidos" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>&Aacute;rea</h3>}
                    name="area"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                      },
                    ]}
                  >
                    <Input placeholder="¿A qué área que desea postular?" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>Ciudad</h3>}
                    name="ciudad"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa tu ciudad" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>Email</h3>}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                        type: "email",
                      },
                    ]}
                  >
                    <Input placeholder="Ingresa tu email" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <Form.Item
                    label={<h3>Ingresa tu CV</h3>}
                    name="cv"
                    rules={[
                      {
                        required: true,
                        message: "Completa este campo",
                      },
                    ]}
                    className="itemFileBolsa"
                  >
                    <label className="custom-label-bolsa-page">
                      {fileName}
                      <Input
                        size="small"
                        type="file"
                        hidden
                        onChange={onChangeFile}
                        accept="application/pdf"
                      />
                    </label>
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Form.Item>
                    {spin ? (
                      <Spin />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        icon={<img src={correo} alt="Enviar" width={25} />}
                      >
                        &nbsp; Enviar
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent bolsa />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default BolsaTrabajoPage;
