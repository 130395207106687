import { Fragment, useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import AppBarComponent from "../components/appbar.component";
import FooterComponent from "../components/footer.component";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";
import ErrorPage from "./error.page";
import LoadPage from "./load.page";
import "../../styles/pages/opinion.page.css";
import { Button, Modal, Row, Spin } from "antd";
import { Col } from "antd";
import { Form } from "antd";
import { Input } from "antd";
import correo from "../../assets/icons/correo.svg";
import Global from "../../global/global";
import TiendasContext from "../../providers/tiendas-provider";
import { APIResponse } from "../../models/api_response";
import { Tienda } from "../../models/tienda";
import { Select } from "antd";

function OpinionPage() {
  const apiTiendas = useContext(TiendasContext);
  const [listaTiendas, setListaTiendas] = useState<Tienda[]>([]);
  const [mounted, setMounted] = useState<any>(null);
  const [formOpinion] = Form.useForm();
  const { TextArea } = Input;
  const [spin, setSpin] = useState<boolean>(false);
  const { Option } = Select;

  useEffect(() => {
    setMounted(true);
    apiTiendas.listarTiendas().then((response: APIResponse<Tienda[]>) => {
      if (response.hasData) {
        setListaTiendas(response.data);
      }
    });
    return () => {
      setMounted(false);
    };
  }, [apiTiendas]);

  const onFinish = (body: any) => {
    setSpin(true);
    const SibApiV3Sdk: any = require("sib-api-v3-typescript");
    let apiInstance: any = new SibApiV3Sdk.TransactionalEmailsApi();
    let apiKey: any = apiInstance.authentications["apiKey"];
    apiKey.apiKey = Global.API_KEY_SENDINBLUE;
    let sendSmtpEmail = new SibApiV3Sdk.SendSmtpEmail();
    sendSmtpEmail.subject = "POST VENTA";
    sendSmtpEmail.htmlContent = `
    <!DOCTYPE html>
    <html lang="es">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>SOLICITUD DE TRABAJO - 3A AMSEQ</title>
        <style>
          body {
            font-family: sans-serif;
          }
    
          .content {
            text-align: center;
            padding: 20px;
            border-radius: 10px;
          }
    
          .content h3 {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(244, 220, 43, 1);
            padding: 20px;
            border-radius: 10px;
          }
    
          .content .mail {
            background: rgb(34, 47, 75, 1) !important;
            color: rgb(255, 255, 255, 1);
            padding: 20px;
            border-radius: 10px;
          }
        </style>
      </head>
      <body>
        <div class="content">
          <h3>3A AMSEQ - DANOS TU OPINIÓN</h3>
          <div class="mail">
            <p>Nombres: <b>${body["nombre"]}</b></p>
            <p>Empresa: <b>${body["empresa"]}</b></p>
            <p>Email: <b>${body["email"]}</b></p>
            <p>Celular: <b>${body["celular"]}</b></p>
            <p>Tienda: <b>${body["tienda"]}</b></p>
            <p>Atención recibida: <b>${body["atencion_recibida"]}</b></p>
            <p>Asesor: <b>${body["asesor"]}</b></p>
            <p>Encontró productos: <b>${body["encontro_productos"]}</b></p>
            <p>Probabilidad de recomendación: <b>${body["probabilidad_recomendacion"]}</b></p>
            <p>Mensaje: <b>${body["mensaje"]}</b></p>
          </div>
        </div>
      </body>
    </html>
    `;
    sendSmtpEmail.sender = {
      name: body["nombre"],
      email: body["email"],
    };
    sendSmtpEmail.to = [
      {
        // email: "opinion@3aamseq.com.pe",
        email: "postventa@3aamseq.com.pe",
        name: "POST VENTA 3AAMSEQ",
      },
    ];

    sendSmtpEmail.replyTo = {
      email: body["email"],
      name: body["nombre"],
    };

    apiInstance.sendTransacEmail(sendSmtpEmail).then(
      function (data: any) {
        Modal.success({
          title: "3AAMSEQ",
          content:
            "Muchas gracias por su opinión, nos ayuda a seguir mejorando",
        });
        formOpinion.resetFields();
        setSpin(false);
      },
      function (error: any) {
        Modal.error({
          title: "3AAMSEQ",
          content: "Ocurrió un error, inténtalo más tarde",
        });
        setSpin(false);
      }
    );
  };

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row className="opinion-page">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-opinion-page">DANOS TU OPINI&Oacute;N</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <p className="texto-opinion-page">
              Completa esta encuesta si deseas dejar tu opini&oacute;n y
              as&iacute; ayudarnos a seguir mejorando nuestros servicios:
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
              name="formOpinion"
              onFinish={onFinish}
              form={formOpinion}
              className="form-opinion-page"
              layout="inline"
            >
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Nombre y Apellido</h3>}
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingrese su nombre completo" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Empresa o Raz&oacute;n Social</h3>}
                  name="empresa"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu razón social o el nombre de tu empresa" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Email</h3>}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                      type: "email",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu email" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>Tel&eacute;fono o Celular</h3>}
                  name="celular"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa tu telefono o celular" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>¿En qu&eacute; tienda fuiste atendido?</h3>}
                  name="tienda"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Select showSearch placeholder="Selecciona una tienda">
                    {listaTiendas.length > 0 ? (
                      listaTiendas.map((tienda: Tienda, _index: number) => (
                        <Option
                          value={tienda.nombre}
                          key={`tienda-${tienda.id}`}
                        >
                          {tienda.nombre}
                        </Option>
                      ))
                    ) : (
                      <Option value="0">Selecciona una tienda</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={
                    <h3>¿C&oacute;mo fue la atenci&oacute;n en tu compra?</h3>
                  }
                  name="atencion_recibida"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona una opción">
                    <Option value="Excelente">Excelente</Option>
                    <Option value="Buena">Buena</Option>
                    <Option value="Regular">Regular</Option>
                    <Option value="Pésima">Pésima</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={<h3>¿Qui&eacute;n le atendi&oacute;?</h3>}
                  name="asesor"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Input placeholder="Ingresa el nombre del asesor o asesora" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={
                    <h3>¿Encontr&oacute; todos los productos que buscaba?</h3>
                  }
                  name="encontro_productos"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona una opción">
                    <Option value="SI">SI</Option>
                    <Option value="NO">NO</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={
                    <h3>
                      ¿Cu&aacute;l es la probabilidad de que <br /> nos
                      recomiende con sus conocidos?
                    </h3>
                  }
                  name="probabilidad_recomendacion"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <Select placeholder="Selecciona una opción">
                    <Option value="Muy probable">Muy probable</Option>
                    <Option value="Es probable">Es probable</Option>
                    <Option value="Es poco probable">Es poco probable</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <Form.Item
                  label={
                    <h3>
                      ¿Tiene alg&uacute;n otro comentario o <br /> sugerencia
                      para nosotros?
                    </h3>
                  }
                  name="mensaje"
                  rules={[
                    {
                      required: true,
                      message: "Completa este campo",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="Ingresa tu mensaje"
                    showCount
                    maxLength={300}
                  />
                </Form.Item>
              </Col>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Form.Item>
                  {spin ? (
                    <Spin />
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<img src={correo} alt="Enviar" width={25} />}
                    >
                      &nbsp; Enviar
                    </Button>
                  )}
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent opinion />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default OpinionPage;
