import { APIResponse } from "../models/api_response";
import { Tienda } from "../models/tienda";
import { API } from "./api";

export class APITiendas extends API {
  constructor() {
    super("/tiendas/");
  }

  async listarTiendas(): Promise<APIResponse<Tienda[]>> {
    const result: APIResponse<any[]> = await this.get("", { auth: false });
    if (result.hasException) {
      return result as any;
    }
    const tiendas: Tienda[] = [];
    result.data!.forEach((tienda: any, _index: number) => {
      tiendas.push(new Tienda(tienda));
    });
    result.data = tiendas;
    return result as any;
  }
}
