import "../../styles/pages/error.page.css";
import errordata from "../../assets/icons/errordata.svg";

function ErrorPage() {
  return (
    <div className="error-page">
      <img src={errordata} alt="3A AMSEQ" title="3A AMSEQ" />
    </div>
  );
}
export default ErrorPage;
