import "../../styles/widgets/categoria.card.css";
import { ArrowRightOutlined } from "@mui/icons-material";
import { Card } from "antd";
import { Categoria } from "../../models/categoria";
import { Fragment } from "react";
import { Skeleton } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Global from "../../global/global";

function CategoriaCard({ categoria }: { categoria: Categoria }) {
  const [load, setLoad] = useState<boolean>(false);
  const { Meta } = Card;
  const navigate = useNavigate();

  return (
    <Card
      key={`card-categoria-${categoria.id}`}
      className="categoria-card"
      cover={
        <Fragment>
          {!load && <Skeleton.Image />}
          <img
            alt={categoria.nombre}
            title={categoria.nombre}
            src={`${Global.URL_FOTOS_CATEGORIAS}${categoria.foto}`}
            className={load ? "visible" : "hide"}
            onLoad={() => {
              setLoad(true);
            }}
          />
        </Fragment>
      }
      onClick={() => {
        localStorage.setItem("categoria", JSON.stringify(categoria));
        navigate("/productos");
      }}
    >
      <Meta title={categoria.nombre} />
      <ArrowRightOutlined />
    </Card>
  );
}

export default CategoriaCard;
