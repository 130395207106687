export interface MarcaDataI {
  id?: number;
  nombre?: string;
  foto?: string;
}

export class Marca implements MarcaDataI {
  id?: number;
  nombre?: string;
  foto?: string;
  constructor(data: MarcaDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.foto = data.foto;
  }
}
