import { APIResponse } from "../models/api_response";
import { Promocion } from "../models/promocion";
import { API } from "./api";

export class APIPromociones extends API {
  constructor() {
    super("/promociones/");
  }

  async listarPromociones(): Promise<APIResponse<Promocion[]>> {
    const result: APIResponse<any[]> = await this.get("", { auth: false });
    if (result.hasException) {
      return result as any;
    }
    const promociones: Promocion[] = [];
    result.data!.forEach((promocion: any, _index: number) => {
      promociones.push(new Promocion(promocion));
    });
    result.data = promociones;
    return result as any;
  }
}
