export interface CategoriaDataI {
  id: number;
  nombre: string;
  foto: string;
}

export class Categoria implements CategoriaDataI {
  id: number;
  nombre: string;
  foto: string;
  constructor(data: CategoriaDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.foto = data.foto;
  }
}
