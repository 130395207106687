import { APIResponse } from "../models/api_response";
import { Marca } from "../models/marca";
import { API } from "./api";

export class APIMarcas extends API {
  constructor() {
    super("/marcas/");
  }

  async listarMarcas(): Promise<APIResponse<Marca[]>> {
    const result: APIResponse<any[]> = await this.get("", { auth: false });
    if (result.hasException) {
      return result as any;
    }
    const marcas: Marca[] = [];
    result.data!.forEach((marca: any, _index: number) => {
      marcas.push(new Marca(marca));
    });
    result.data = marcas;
    return result as any;
  }
}
