import "../../styles/widgets/banner.card.css";
import { Fragment } from "react";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";
import { Banner } from "../../models/banner";

function BannerCard({ banner }: { banner: Banner }) {
  const [load, setLoad] = useState<boolean>(false);

  return (
    <Fragment>
      {!load && <Skeleton.Image />}
      <img
        src={`${Global.URL_FOTOS_BANNERS}${banner.url}`}
        alt="3AAMSEQ"
        title="3AAMSEQ"
        className={load ? "visible image-banner-card" : "hide"}
        onLoad={() => {
          setLoad(true);
        }}
      />
    </Fragment>
  );
}

export default BannerCard;
