import { Button, Col, Collapse, Row } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router";
// import { APIMarcas } from "../../api/api_marcas";
import { APIProductos } from "../../api/api_productos";
import { APIResponse } from "../../models/api_response";
import { Categoria } from "../../models/categoria";
import { Marca } from "../../models/marca";
import { Pagination } from "../../models/pagination";
import { Producto } from "../../models/producto";
// import MarcasContext from "../../providers/marcas-provider";
import ProductosContext from "../../providers/productos-provider";
import AppBarComponent from "../components/appbar.component";
import FooterComponent from "../components/footer.component";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";
import ErrorPage from "./error.page";
import LoadPage from "./load.page";
import "../../styles/pages/productos.page.css";
import ProductoCard from "../widgets/producto.card";
import NoData from "../widgets/nodata.card";
import { Pagination as PageAnt } from "antd";
import { Checkbox } from "antd";
import Global from "../../global/global";

function ProductosPage() {
  const [mounted, setMounted] = useState<any>(null);
  const apiProductos = useContext<APIProductos>(ProductosContext);
  // const apiMarcas = useContext<APIMarcas>(MarcasContext);
  const [productos, setProductos] = useState<Producto[]>([]);
  const [marcas, setMarcas] = useState<Marca[]>([]);
  const [categoria, setCategoria] = useState<Categoria>();
  const [enableDropFilter, setEnableDropFilter] = useState<boolean>(true);

  const [productosResponse, setProductosResponse] = useState<
    APIResponse<Pagination<Producto>>
  >(APIResponse.loading);
  const [marcasSeleccionadas, setMarcasSeleccionadas] = useState<number[]>([]);
  const navigate = useNavigate();
  const { meta } = productosResponse.data || {};
  const { Panel } = Collapse;
  const listarProductos = useCallback(
    ({
      categoria,
      marcas,
      search,
      offset,
      count,
    }: {
      categoria: Categoria;
      marcas?: number[];
      search?: string;
      offset?: number;
      count?: number;
    }) => {
      setProductos([]);
      setProductosResponse(APIResponse.loading);
      let marca: any = undefined;
      let nombre: any = undefined;
      if (marcas && marcas.length > 0) {
        marca = marcas?.join();
      } else {
        marca = undefined;
      }
      if (search) {
        nombre = search;
      } else {
        nombre = undefined;
      }

      apiProductos
        .listarProductos({
          categoria: categoria.id.toString(),
          marca: marca,
          search: nombre,
          offset: offset,
          count: count,
        })
        .then((response) => {
          if (response.hasData) {
            if (!marcas) {
              let _listaMarcas: Marca[] = [];
              response.data.items.forEach(
                (producto: Producto, _index: number) => {
                  _listaMarcas.push(producto.marca!);
                }
              );
              setMarcas(Global.arrayUnique(_listaMarcas, "nombre"));
            }
            setProductos(response.data.items);
            setProductosResponse(response);
          }
        });
    },
    [apiProductos]
  );

  // const listarMarcas = useCallback(() => {
  // apiMarcas.listarMarcas().then((response) => {
  //   if (response.hasData) {
  //     setMarcas(response.data);
  //   }
  // });
  // }, [apiMarcas]);

  const onChangePagination = async (current: number, pageSize?: number) => {
    setProductosResponse(productosResponse.withLoading);
    listarProductos({
      categoria: categoria!,
      marcas: marcasSeleccionadas,
      offset: current,
      count: 12,
    });
  };

  const onDropFilter = () => {
    listarProductos({ categoria: categoria!, offset: 1, count: 12 });
    setMarcasSeleccionadas([]);
    setEnableDropFilter(true);
  };

  const onSelectedMarcas = (marcas: any) => {
    if (marcas.length > 0) {
      setEnableDropFilter(false);
      listarProductos({
        categoria: categoria!,
        marcas: marcas,
        offset: 1,
        count: 12,
      });
    } else {
      setEnableDropFilter(true);
      listarProductos({ categoria: categoria!, offset: 1, count: 12 });
    }
    setMarcasSeleccionadas(marcas);
  };

  const onSearchProductos = (nombre: string) => {
    if (nombre.length > 0) {
      listarProductos({
        categoria: categoria!,
        marcas: marcasSeleccionadas,
        search: nombre,
        offset: 1,
        count: 12,
      });
    } else {
      listarProductos({ categoria: categoria!, offset: 1, count: 12 });
    }
  };

  useEffect(() => {
    setMounted(true);
    let categoria: Categoria;
    if (localStorage.getItem("categoria")) {
      categoria = new Categoria(JSON.parse(localStorage.getItem("categoria")!));
      setCategoria(categoria);
      // listarMarcas();
      listarProductos({ categoria: categoria, offset: 1, count: 12 });
    } else {
      navigate("/catalogo");
    }
    return () => {
      setMounted(false);
    };
  }, [listarProductos, navigate]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent catalogo />
      <SubAppBarComponent search onSearchProductos={onSearchProductos} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-productos-page">{categoria!.nombre}</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Row className="row-listaproductos-productos-page">
              <Col xs={24} sm={24} md={24} lg={5} xl={5} xxl={5}>
                <h3 className="subtitulo-productos-page">FILTRAR PRODUCTOS</h3>
                <Row>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    className="col-collapse-productos-page"
                  >
                    <Collapse expandIconPosition="right" accordion>
                      <Panel
                        key="1"
                        header="MARCA"
                        className="panel-productos-page"
                      >
                        <Checkbox.Group
                          onChange={onSelectedMarcas}
                          value={marcasSeleccionadas}
                        >
                          <Row>
                            {marcas.map((marca: Marca, _index: number) => (
                              <Col
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                key={`marca-seleccionada${_index}`}
                              >
                                <Checkbox value={marca.id}>
                                  <h4>{marca.nombre}</h4>
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    className="col-button-productos-page"
                  >
                    <Button
                      type="ghost"
                      disabled={enableDropFilter}
                      onClick={onDropFilter}
                      danger
                      className="button-drop-filter-productos-page"
                    >
                      BORRAR FILTROS
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={19} xl={19} xxl={19}>
                {productos.length > 0 ? (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <Row>
                        {productos.map((producto: Producto, _index: number) => (
                          <ProductoCard
                            producto={producto}
                            key={`producto-card-${_index}`}
                          />
                        ))}
                      </Row>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      xxl={24}
                      className="col-pagination-productos-page"
                    >
                      <PageAnt
                        pageSize={meta ? meta.itemsPerPage : 0}
                        current={meta ? meta.currentPage : 0}
                        total={meta ? meta.totalItems : 0}
                        onChange={onChangePagination}
                        showSizeChanger={false}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                      <NoData />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default ProductosPage;
