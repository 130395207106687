import "../../styles/components/subappbar.component.css";
import { Col } from "antd";
import { Fragment } from "react";
import { PhoneInTalk } from "@mui/icons-material";
import { Row } from "antd";
import { Search as SearchIcon } from "@mui/icons-material";
import { Space } from "antd";
import { Input } from "antd";

function SubAppBarComponent({
  onSearchProductos,
  search,
}: {
  onSearchProductos: Function;
  search?: boolean;
}) {
  const { Search } = Input;

  return (
    <Fragment>
      <Row className="subappbar">
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          {search ? (
            <Search
              placeholder="¿Qué estás buscando?"
              allowClear
              enterButton={<SearchIcon />}
              onSearch={(e) => {
                onSearchProductos(e);
              }}
              // onChange={(e) => {
              //   onSearchProductos(e.target.value);
              // }}
            />
          ) : (
            ""
          )}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <a href="https://api.whatsapp.com/send?phone=51933515889" target="_blank" rel="noreferrer">
            <Space>
              <h3>
                <PhoneInTalk />
                &nbsp; Servicio al cliente
              </h3>
              <h3 className="phone-subappbar">933515889</h3>
            </Space>
          </a>
        </Col>
      </Row>
    </Fragment>
  );
}

export default SubAppBarComponent;
