import { APIResponse } from "../models/api_response";
import { Pagination, PaginationI } from "../models/pagination";
import { Producto } from "../models/producto";
import { API } from "./api";

export class APIProductos extends API {
  constructor() {
    super("/productos/");
  }

  async listarProductos({
    categoria,
    marca,
    search,
    precioFrom,
    precioTo,
    offset,
    count,
  }: {
    categoria: string;
    marca?: string;
    search?: string;
    precioFrom?: number;
    precioTo?: number;
    offset?: number;
    count?: number;
  }): Promise<APIResponse<Pagination<Producto>>> {
    let query = `${categoria ?? null}&${marca ?? null}&${search ?? ""}&${
      precioFrom ?? null
    }&${precioTo ?? null}&${offset ?? null}&${count ?? null}`;
    const result: APIResponse<PaginationI> = await this.get(query, {});
    if (result.hasException) {
      return result as APIResponse<Pagination<Producto>>;
    }
    const items: Producto[] = [];
    result.data.items!.forEach((value) => {
      items.push(new Producto(value));
    });
    result.data = new Pagination(items, result.data.meta);
    return result as APIResponse<Pagination<Producto>>;
  }
}
