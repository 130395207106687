import "../../styles/pages/construccion.page.css";
import construccion from "../../assets/icons/construccion.gif";

function ConstruccionPage() {
  return (
    <div className="construccion-page">
      <img src={construccion} alt="3A AMSEQ" title="3A AMSEQ" />
    </div>
  );
}
export default ConstruccionPage;
