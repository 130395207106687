import "../../styles/pages/ubicanos.page.css";
import { APIResponse } from "../../models/api_response";
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";
import { Tienda } from "../../models/tienda";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import ErrorPage from "./error.page";
import LoadPage from "./load.page";
import NoData from "../widgets/nodata.card";
import TiendaCard from "../widgets/tienda.card";
import TiendasContext from "../../providers/tiendas-provider";
import SubAppBarComponent from "../components/subappbar.component";
import FooterComponent from "../components/footer.component";
import SubFooterComponent from "../components/subfooter.component";

function UbicanosPage() {
  const apiTiendas = useContext(TiendasContext);
  const [listaTiendas, setListaTiendas] = useState<Tienda[]>([]);
  const [mounted, setMounted] = useState<any>(null);

  useEffect(() => {
    setMounted(true);
    apiTiendas.listarTiendas().then((response: APIResponse<Tienda[]>) => {
      if (response.hasData) {
        setListaTiendas(response.data);
      }
    });
    return () => {
      setMounted(false);
    };
  }, [apiTiendas]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent ubicanos />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-ubicanos-page">NUESTRAS TIENDAS</h1>
          </Col>
          {listaTiendas.length > 0 ? (
            <Fragment>
              {listaTiendas.map((tienda: Tienda, _index: number) =>
                !tienda.nombre.match(/OFICINA PRINCIPAL.*/) ? (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                    key={`tienda-${tienda.id}-${_index}`}
                  >
                    <TiendaCard tienda={tienda} />
                  </Col>
                ) : (
                  <br key={`tienda-${tienda.id}-${_index}`} />
                )
              )}
              {/* <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <h1 className="titulo-ubicanos-page">OFICINA PRINCIPAL</h1>
              </Col>
              {listaTiendas.map((tienda: Tienda, _index: number) => */}
                {/* tienda.nombre.match(/OFICINA PRINCIPAL.) ? ( 
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    xxl={6}
                    key={`tienda-${tienda.id}-${_index}`}
                  >
                    <TiendaCard tienda={tienda} />
                  </Col>
                ) : (
                  <br key={`tienda-${tienda.id}-${_index}`} />
                )
              )}*/}
            </Fragment>
          ) : (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <NoData />
            </Col>
          )}
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default UbicanosPage;
