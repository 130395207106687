import { APIResponse } from "../models/api_response";
import { Categoria } from "../models/categoria";
import { API } from "./api";

export class APICategorias extends API {
  constructor() {
    super("/categorias/");
  }

  async listarCategorias(): Promise<APIResponse<Categoria[]>> {
    const result: APIResponse<any[]> = await this.get("", { auth: false });
    if (result.hasException) {
      return result as any;
    }
    const categorias: Categoria[] = [];
    result.data!.forEach((categoria: any, _index: number) => {
      categorias.push(new Categoria(categoria));
    });
    result.data = categorias;
    return result as any;
  }
}
