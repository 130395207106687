import { Fragment, useContext, useEffect, useState } from "react";
import AppBarComponent from "../components/appbar.component";
import FooterComponent from "../components/footer.component";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";
import "../../styles/pages/catalogo.page.css";
import CategoriasContext from "../../providers/categorias-provider";
import { Categoria } from "../../models/categoria";
import { APIResponse } from "../../models/api_response";
import LoadPage from "./load.page";
import ErrorPage from "./error.page";
import { Col, Row } from "antd";
import NoData from "../widgets/nodata.card";
import CategoriaCard from "../widgets/categoria.card";
function CatalogoPage() {
  const apiCategorias = useContext(CategoriasContext);
  const [listaCategorias, setListaCategorias] = useState<Categoria[]>([]);
  const [mounted, setMounted] = useState<any>(null);

  useEffect(() => {
    setMounted(true);
    apiCategorias
      .listarCategorias()
      .then((response: APIResponse<Categoria[]>) => {
        if (response.hasData) {
          setListaCategorias(response.data);
        }
      });
    return () => {
      setMounted(false);
    };
  }, [apiCategorias]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent catalogo />
      <SubAppBarComponent  onSearchProductos={() => {}} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-catalogo-page">CATEGORIAS</h1>
          </Col>
          {listaCategorias.length > 0 ? (
            <Fragment>
              {listaCategorias.map((categoria: Categoria, _index: number) => (
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  xxl={8}
                  key={`categoria-${categoria.id}-${_index}`}
                >
                  <CategoriaCard categoria={categoria} />
                </Col>
              ))}
            </Fragment>
          ) : (
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <NoData />
            </Col>
          )}
        </Row>
      </div>

      <SubFooterComponent />
      <FooterComponent />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}
export default CatalogoPage;
