export interface ActividadDataI {
  id?: number;
  descripcion?: string;
  fotos?: string[];
}

export class Actividad implements ActividadDataI {
  id?: number;
  descripcion?: string;
  fotos?: string[];
  constructor(data: ActividadDataI) {
    this.id = data.id;
    this.descripcion = data.descripcion;
    this.fotos = data.fotos;
  }
}
