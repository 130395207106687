export interface PromocionDataI {
  id?: number;
  descripcion?: string;
  imagen?: string;
}

export class Promocion implements PromocionDataI {
  id?: number;
  descripcion?: string;
  imagen?: string;
  constructor(data: PromocionDataI) {
    this.id = data.id;
    this.descripcion = data.descripcion;
    this.imagen = data.imagen;
  }
}
