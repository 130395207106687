import "../../styles/pages/nosotros.page.css";
import { APIBanners } from "../../api/api_banners";
import { Banner } from "../../models/banner";
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";
import { Skeleton } from "antd";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import BannersContext from "../../providers/banners-provider";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import Global from "../../global/global";
import integridad from "../../assets/interface/integridad.svg";
import LoadPage from "./load.page";
import mejora from "../../assets/interface/mejora.svg";
import responsabilidad from "../../assets/interface/responsabilidad.svg";
import servicio from "../../assets/interface/servicio.svg";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";
import trabajo from "../../assets/interface/trabajo.svg";

function NosotrosPage() {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [mounted, setMounted] = useState<any>(null);
  const apiBanners = useContext<APIBanners>(BannersContext);

  useEffect(() => {
    setMounted(true);
    apiBanners.listarBanners("Nosotros").then((response) => {
      if (response.hasData) {
        setBanners(response.data);
      }
    });
    return () => {
      setMounted(false);
    };
  }, [apiBanners]);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent nosotros />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-nosotros-page">NOSOTROS</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <h2 className="subtitulo-nosotros-page">MISI&Oacute;N</h2>
                <h2 className="texto-nosotros-page">
                  "Comercializamos materiales de acero, accesorios y
                  herramientas para construcciones estructurales, brindando un
                  abastecimiento óptimo, despacho oportuno y asesoramiento
                  técnico continuo, garantizando a nuestros clientes un servicio
                  de calidad y el desarrollo de sus proyectos de forma segura,
                  confiable y sostenible"
                </h2>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                <h2 className="subtitulo-nosotros-page">VISI&Oacute;N</h2>
                <h2 className="texto-nosotros-page">
                  "Ser una de las empresas líder en la comercialización de
                  materiales de acero para construcciones estructurales en todo
                  el territorio nacional, enfocados en superar las expectativas
                  de nuestros clientes, impulsando su crecimiento y
                  convirtiéndonos en socios estratégicos de sus proyectos,
                  basados en una óptima gestión por procesos que garanticen la
                  calidad de nuestros productos y servicios de forma sostenible"
                </h2>
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={12}
            xl={12}
            xxl={12}
            className="div-banner-nosotros-page"
          >
            {banners.length > 0 ? (
              <Fragment>
                {!load && <Skeleton.Image />}
                <img
                  src={`${Global.URL_FOTOS_BANNERS}${banners[0].url}`}
                  alt="3A AMSEQ"
                  title="NOSOTROS"
                  className={load ? "visible banner-nosotros-page" : "hide"}
                  onLoad={() => {
                    setLoad(true);
                  }}
                />
              </Fragment>
            ) : (
              ""
            )}
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h2 className="subtitulo-nosotros-page">VALORES</h2>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="col-icon-valores-nosotros-page"
              >
                <img
                  src={integridad}
                  alt="Integridad"
                  title="Integridad"
                  className="icon-valores-nosotros-page"
                />
                <h3>Integridad</h3>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="col-icon-valores-nosotros-page"
              >
                <img
                  src={responsabilidad}
                  alt="Responsabilidad"
                  title="Responsabilidad"
                  className="icon-valores-nosotros-page"
                />
                <h3>Responsabilidad</h3>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="col-icon-valores-nosotros-page"
              >
                <img
                  src={trabajo}
                  alt="Trabajo en equipo"
                  title="Trabajo en equipo"
                  className="icon-valores-nosotros-page"
                />
                <h3>Trabajo en equipo</h3>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="col-icon-valores-nosotros-page"
              >
                <img
                  src={servicio}
                  alt="Actitud de servicio"
                  title="Actitud de servicio"
                  className="icon-valores-nosotros-page"
                />
                <h3>Actitud de servicio</h3>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                className="col-icon-valores-nosotros-page"
              >
                <img
                  src={mejora}
                  alt="Mejora continua"
                  title="Mejora continua"
                  className="icon-valores-nosotros-page"
                />
                <h3>Mejora continua</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default NosotrosPage;
