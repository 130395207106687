import { APIResponse } from "../models/api_response";
import { API } from "./api";

export class APICupones extends API {
  constructor() {
    super("/cupones/");
  }

  async insertarCupon(nombres: string, email: string, celular: string, documento: string): Promise<APIResponse<any>> {
    const result: APIResponse<any[]> = await this.get(`"${nombres}","${email}","${celular}","${documento}"`, { auth: false });
    if (result.hasException) {
      return result as any;
    }
    return result as any;
  }
}
