import "../../styles/widgets/actividad.card.css";
import { Actividad } from "../../models/actividad";
import { ArrowRightOutlined } from "@mui/icons-material";
import { Button } from "antd";
import { Carousel } from "antd";
import { Col } from "antd";
import { Fragment } from "react";
import { Modal } from "antd";
import { Row } from "antd";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";
import ActividadDetalleCard from "./actividaddetalle";

function ActividadCard({ actividad }: { actividad: Actividad }) {
  const [load, setLoad] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  return (
    <Fragment>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Row className="row-actividad-card">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            {!load && <Skeleton.Image />}
            <img
              alt={actividad.descripcion}
              title={actividad.descripcion}
              src={`${Global.URL_FOTOS_ACTIVIDADES}${actividad.fotos![0]}`}
              className={load ? "visible foto-actividad-card" : "hide"}
              onLoad={() => {
                setLoad(true);
              }}
              onClick={() => {
                setModal(true);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div className="div-titulo-actividad-card">
              <h1 className="titulo-actividad-card">{actividad.descripcion}</h1>
            </div>
            <div className="div-ver-mas-actividad-card">
              <Button
                className="ver-mas-actividad-card"
                onClick={() => {
                  setModal(true);
                }}
              >
                VER M&Aacute;S <ArrowRightOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </Col>

      {/* Modal para ver las fotos completas de la actividad */}
      <Modal
        visible={modal}
        footer={[]}
        width={1000}
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => {
          setModal(false);
        }}
        className="modal-actividad-card"
        centered
      >
        <Carousel arrows>
          {actividad.fotos!.map(
            (foto: string, _index: number) => (
              <ActividadDetalleCard
                foto={foto}
                actividad={actividad}
                key={`foto-actividad-${_index}`}
              />
            )
            // return (
            //   <Fragment>
            //     {!loadBig && <Skeleton.Image />}
            //     <img
            //       key={`foto-actividad-${_index}`}
            //       src={`${Global.URL_FOTOS_ACTIVIDADES}${foto}`}
            //       alt={actividad.descripcion}
            //       title={actividad.descripcion}
            //       className={
            //         loadBig ? "visible foto-actividad-detalle-card" : "hide"
            //       }
            //       onLoad={() => {
            //         setLoadBig(true);
            //       }}
            //     />
            //     ;
            //   </Fragment>
            // );
          )}
        </Carousel>
      </Modal>
    </Fragment>
  );
}

export default ActividadCard;
