export interface BannerDataI {
  id?: number;
  nombre?: string;
  url?: string;
}

export class Banner implements BannerDataI {
  id?: number;
  nombre?: string;
  url?: string;
  constructor(data: BannerDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.url = data.url;
  }
}
