import "../../styles/pages/load.page.css";
import { Spin } from "antd";
function LoadPage() {
  return (
    <div className="load-page">
      <Spin />
    </div>
  );
}

export default LoadPage;
