import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./styles/styles.css";
import App from "./views/pages/app";

ReactDOM.render(
  //   <React.StrictMode>{<App />}</React.StrictMode>,
  <App />,
  document.getElementById("root")
);
