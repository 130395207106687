import "../../styles/pages/libro.page.css";
import { Col } from "antd";
import { Fragment } from "react";
import { Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import AppBarComponent from "../components/appbar.component";
import ConstruccionPage from "./construccion.page";
import ErrorPage from "./error.page";
import FooterComponent from "../components/footer.component";
import LoadPage from "./load.page";
import SubAppBarComponent from "../components/subappbar.component";
import SubFooterComponent from "../components/subfooter.component";

function LibroPage() {
  const [mounted, setMounted] = useState<any>(null);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <AppBarComponent />
      <SubAppBarComponent onSearchProductos={() => {}} />
      <div className="content">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <h1 className="titulo-libro-page">LIBRO DE RECLAMACIONES</h1>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <ConstruccionPage />
          </Col>
        </Row>
      </div>
      <SubFooterComponent />
      <FooterComponent libro />
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default LibroPage;
