import "../../styles/widgets/fotoproducto.card.css";
import { Fragment } from "react";
import { ProductoVariante } from "../../models/producto_variante";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";

function FotoProductoCard({ producto }: { producto: ProductoVariante }) {
  const [load, setLoad] = useState<boolean>(false);
  return (
    <Fragment>
      {!load && <Skeleton.Image />}
      <img
        src={`${Global.URL_FOTOS_PRODUCTOS}${producto.foto}`}
        alt={producto.descripcion}
        title={producto.descripcion}
        className={
          load
            ? producto.descripcion?.match("DISCO")
              ? "imagespin-fotoproducto-card"
              : "image-fotoproducto-card"
            : "hide"
        }
        onLoad={() => {
          setLoad(true);
        }}
      />
    </Fragment>
  );
}
export default FotoProductoCard;
