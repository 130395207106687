import "../../styles/pages/notfound.page.css";
import { Col } from "antd";
import { Facebook } from "@mui/icons-material";
import { Fragment } from "react";
import { Home } from "@mui/icons-material";
import { Instagram } from "@mui/icons-material";
import { Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { YouTube } from "@mui/icons-material";
import ErrorPage from "./error.page";
import LoadPage from "./load.page";
import notfound from "../../assets/icons/notfound.svg";

function NotFoundPage() {
  const [mounted, setMounted] = useState<any>(null);
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  if (mounted === null) {
    return <LoadPage />;
  }

  return mounted ? (
    <Fragment>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="content-notfound-page"
        >
          <img
            src={notfound}
            alt="3A AMSEQ"
            title="PÁGINA NO ENCONTRADA"
            className="logo-notfound-page"
          />
          <h1 className="notfound-page">404</h1>
          <h1 className="titulo-notfound-page">P&Aacute;GINA NO ENCONTRADA</h1>
          <h1 className="texto-notfound-page">
            Se la llevaron los extraterrestres
          </h1>
          <div className="div-icons-notfound-page">
            <div className="div-icon-notfound-page">
              <a href="/">
                <Home className="icon-notfound-page" fontSize="large" />
              </a>
            </div>
            <div className="div-icon-notfound-page">
              <a
                href="https://www.facebook.com/3AAMSEQPERU"
                rel="noreferrer"
                target="_blank"
              >
                <Facebook className="icon-notfound-page" fontSize="large" />
              </a>
            </div>
            <div className="div-icon-notfound-page">
              <a
                href="https://www.instagram.com/3aamseqperu/"
                rel="noreferrer"
                target="_blank"
              >
                <Instagram className="icon-notfound-page" fontSize="large" />
              </a>
            </div>
            <div className="div-icon-notfound-page">
              <a
                href="https://www.youtube.com/channel/UCO43nSLPFmLIlEZQwHK7B3w"
                rel="noreferrer"
                target="_blank"
              >
                <YouTube className="icon-notfound-page" fontSize="large" />
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <ErrorPage />
  );
}

export default NotFoundPage;
