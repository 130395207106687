import { APIResponse } from "../models/api_response";
import { Banner } from "../models/banner";
import { API } from "./api";

export class APIBanners extends API {
  constructor() {
    super("/banners/");
  }

  async listarBanners(seccion: string): Promise<APIResponse<Banner[]>> {
    const result: APIResponse<any[]> = await this.get(`${seccion}`, {
      auth: false,
    });
    if (result.hasException) {
      return result as any;
    }
    const banners: Banner[] = [];
    result.data!.forEach((banner: any, _index: number) => {
      banners.push(new Banner(banner));
    });
    result.data = banners;
    return result as any;
  }
}
