import "../../styles/widgets/marca.card.css";
import { Fragment } from "react";
import { Skeleton } from "antd";
import { useState } from "react";
import Global from "../../global/global";
import { Marca } from "../../models/marca";

function MarcaCard({ marca }: { marca: Marca }) {
  const [load, setLoad] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="marca-card">
        {!load && <Skeleton.Image />}
        <img
          src={`${Global.URL_FOTOS_MARCAS}${marca.foto}`}
          alt={marca.nombre}
          title={marca.nombre}
          className={load ? "visible foto-marca-card" : "hide"}
          onLoad={() => {
            setLoad(true);
          }}
        />
      </div>
    </Fragment>
  );
}

export default MarcaCard;
