import "../../styles/widgets/producto.card.css";
import { Carousel } from "antd";
import { Chip } from "@mui/material";
import { Col } from "antd";
import { Producto } from "../../models/producto";
import { ProductoVariante } from "../../models/producto_variante";
import { Row } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import FotoProductoCard from "./fotoproducto.card";

function ProductoCard({ producto }: { producto: Producto }) {
  const [colores, setColores] = useState<string[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);
  const [modelos, setModelos] = useState<string[]>([]);
  const [weight, setWeight] = useState<string[]>([]);

  useEffect(() => {
    const variantes: ProductoVariante[] = producto.variantes!;
    let _colores: string[] = [];
    let _sizes: string[] = [];
    let _modelos: string[] = [];
    let _weight: string[] = [];
    variantes.forEach((variante: ProductoVariante, _index: number) => {
      if (variante.colorCodigo) {
        _colores.push(variante.colorCodigo);
      }
      if (variante.size) {
        _sizes.push(variante.size);
      }
      if (variante.modelo) {
        _modelos.push(variante.modelo);
      }
      if (variante.weight) {
        _weight.push(variante.weight);
      }
    });
    setColores(_colores.filter((v, i, a) => a.indexOf(v) === i));
    setSizes(_sizes.filter((v, i, a) => a.indexOf(v) === i));
    setModelos(_modelos.filter((v, i, a) => a.indexOf(v) === i));
    setWeight(_weight.filter((v, i, a) => a.indexOf(v) === i));
  }, [producto]);

  return (
    <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
      <Row className="card-producto-card">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-foto-producto-card"
        >
          <Carousel effect="scrollx" autoplay>
            {producto.variantes?.map(
              (producto: ProductoVariante, _index: number) => (
                <FotoProductoCard
                  producto={producto}
                  key={`foto-producto-${_index}`}
                />
              )
            )}
          </Carousel>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <h3 className="marca-producto-card">{producto.marca?.nombre}</h3>
          <h3 className="nombre-producto-card">{producto.nombre}</h3>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-colores-producto-card"
        >
          <Row>
            {colores.map((color: string, _index: number) => (
              <Col
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                xxl={2}
                key={`producto-color-${_index}`}
              >
                <div
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    background: color,
                    margin: "auto",
                    border: color === "#FFFFFF" ? "1px solid #000000" : "unset",
                  }}
                ></div>
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-sizes-producto-card"
        >
          <Row>
            {sizes.map((size: string, _index: number) => (
              <Col key={`col-size-${_index}`}>
                <Chip variant="outlined" size="small" label={size} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-modelos-producto-card"
        >
          <Row>
            {modelos.map((modelo: string, _index: number) => (
              <Col key={`col-modelo-${_index}`}>
                <Chip variant="outlined" size="small" label={modelo} />
              </Col>
            ))}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          xxl={24}
          className="col-weight-producto-card"
        >
          <Row>
            {weight.map((weight: string, _index: number) => (
              <Col key={`col-weight-${_index}`}>
                <Chip variant="outlined" size="small" label={weight} />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Col>
  );
}

export default ProductoCard;
