import "../../styles/widgets/nodata.css";
import nodata from "../../assets/icons/nodata.svg";
function NoData() {
  return (
    <div className="nodata">
      <img src={nodata} alt="NO DATA" title="NO DATA" />
    </div>
  );
}

export default NoData;
