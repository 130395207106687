import { APIResponse } from "../models/api_response";
import { Actividad } from "../models/actividad";
import { API } from "./api";

export class APIActividades extends API {
  constructor() {
    super("/actividades/");
  }

  async listarActividades(): Promise<APIResponse<Actividad[]>> {
    const result: APIResponse<any[]> = await this.get("", { auth: false });
    if (result.hasException) {
      return result as any;
    }
    const actividades: Actividad[] = [];
    result.data!.forEach((actividad: any, _index: number) => {
      actividades.push(new Actividad(actividad));
    });
    result.data = actividades;
    return result as any;
  }
}
