import { APIResponse } from "../models/api_response";
import { Cupon } from "../models/cupon";
import { API } from "./api";

export class APIListCupones extends API {
  constructor() {
    super("/lista_cupones/");
  }

  async listarCupones(seccion: string): Promise<APIResponse<Cupon[]>> {
    const result: APIResponse<any[]> = await this.get(`${seccion}`, {
      auth: false,
    });
    if (result.hasException) {
      return result as any;
    }
    const cupones: Cupon[] = [];
    result.data!.forEach((cupon: any, _index: number) => {
      cupones.push(new Cupon(cupon));
    });
    result.data = cupones;
    return result as any;
  }
}
