export interface APIMessage {
  statusCode: number;
  message: string;
}

export interface APIException {
  statusCode: number;
  message: string;
}

export class APIResponse<T> {
  static loading = new APIResponse<any>({ loading: true });
  static empty = new APIResponse<any>();
  data: T;
  message?: APIMessage;
  exception?: APIException;
  loading: boolean;
  private loadMore?: boolean;

  constructor({
    data,
    isException,
    loading,
    loadMore,
  }: {
    data?: any;
    isException?: boolean;
    loading?: boolean;
    loadMore?: boolean;
  } = {}) {
    if (data?.message) {
      if (!isException) {
        this.message = {
          message: data.message,
          statusCode: data.statusCode,
        };
      } else {
        this.exception = {
          message: data.message,
          statusCode: data.statusCode,
        };
      }
    }
    this.data = data?.data;
    this.loading = loading || false;
    if (!this.loading) {
      this.loadMore = loadMore || false;
    }
  }

  get hasData(): boolean {
    return !!this.data;
  }

  get hasException(): boolean {
    return !!this.exception;
  }

  get hasMessage(): boolean {
    return !!this.message;
  }

  get isLoading(): boolean {
    return this.loading;
  }

  get isLoadMore(): boolean {
    return this.loadMore!;
  }

  get withLoading() {
    const loading = new APIResponse<T>({
      isException: false,
      loading: true,
    });
    loading.data = this.data;
    return loading;
  }
}
