import { Categoria } from "./categoria";
import { Marca } from "./marca";
import { ProductoVariante } from "./producto_variante";

export interface ProductoDataI {
  id?: number;
  nombre?: string;
  categoria?: Categoria;
  marca?: Marca;
  variantes?: ProductoVariante[];
}

export class Producto implements ProductoDataI {
  id?: number;
  nombre?: string;
  categoria?: Categoria;
  marca?: Marca;
  variantes?: ProductoVariante[];
  constructor(data: ProductoDataI) {
    this.id = data.id;
    this.nombre = data.nombre;
    this.categoria = data.categoria;
    this.marca = data.marca;
    this.variantes = data.variantes;
  }
}
