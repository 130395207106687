export default class Global {
  static API_KEY_SENDINBLUE =
    "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";
  static TOKEN_DATA_KEY = "TOKEN_DATA_KEY";
  // static URL_API = "http://10.2.5.87/amseq_api/index.php";
  // static URL_FOTOS_BANNERS =
  //   "http://10.2.5.87/amseq_admin/images/page/banners/";
  // static URL_FOTOS_ACTIVIDADES =
  //   "http://10.2.5.87/amseq_admin/images/page/actividades/";
  // static URL_FOTOS_CATEGORIAS =
  //   "http://10.2.5.87/amseq_admin/images/page/categorias/";
  // static URL_FOTOS_MARCAS = "http://10.2.5.87/amseq_admin/images/page/marcas/";
  // static URL_FOTOS_PRODUCTOS =
  //   "http://10.2.5.87/amseq_admin/images/page/productos/";
  // static URL_FOTOS_PROMOCIONES =
  //   "http://10.2.5.87/amseq_admin/images/page/promociones/";
  // static URL_FOTOS_TIENDAS =
  //   "http://10.2.5.87/amseq_admin/images/page/tiendas/";
  // static URL_PDF = "https://www.3aamseq.com.pe/cv3aamseq/docs/";
  // static URL_FOTOS_CUPON = "http://localhost/amseq_cupon/images/page/cupones/";
  // static URL_API = "http://localhost/amseq_api/index.php";
  static URL_API = "https://amseqapi.3aamseq.com.pe/index.php";
  static URL_FOTOS_BANNERS =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/banners/";
  static URL_FOTOS_ACTIVIDADES =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/actividades/";
  static URL_FOTOS_CATEGORIAS =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/categorias/";
  static URL_FOTOS_MARCAS =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/marcas/";
  static URL_FOTOS_PRODUCTOS =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/productos/";
  static URL_FOTOS_PROMOCIONES =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/promociones/";
  static URL_FOTOS_TIENDAS =
    "https://amseqpanel.3aamseq.com.pe/amseq_admin/images/page/tiendas/";
  static URL_PDF = "https://www.3aamseq.com.pe/cv3aamseq/docs/";
  static URL_FOTOS_CUPON = "https://amseqpanel.3aamseq.com.pe/amseq_cupon/images/page/cupones/";

  static arrayUnique(duplicateArray: Array<any>, porperty: string) {
    var uniqueArray: Array<any> = [];
    var searchObject: any = {};
    for (var i in duplicateArray) {
      searchObject[duplicateArray[i][porperty]] = duplicateArray[i];
    }
    for (i in searchObject) {
      uniqueArray.push(searchObject[i] as never);
    }
    return uniqueArray;
  }
}
