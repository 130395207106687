import "../../styles/components/subfooter.component.css";
import { Col } from "antd";
import { Fragment } from "react";
import { Input } from "antd";
import { Modal } from "antd";
import { Row } from "antd";
import { useContext } from "react";
import { useState } from "react";
import correo from "../../assets/icons/correo.svg";
import EmailContext from "../../providers/tiendas-provider copy";
import logo from "../../assets/interface/logo.svg";

function validar_email(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function SubFooterComponent() {
  const apiEmail = useContext(EmailContext);
  const [spin, setSpin] = useState<boolean>(false);
  const { Search } = Input;

  const insertarEmail = async (email: string) => {
    if (email.length > 0 && validar_email(email)) {
      setSpin(true);
      let result = await apiEmail.insertarEmail(email);
      if (result.data !== "0") {
        Modal.success({
          title: "3AAMSEQ",
          content:
            "Gracias por escribirnos, pronto recibirás nuestras ofertas en tu correo",
        });
      } else {
        if (result.message?.statusCode.toString() === "SQLSTATE[23000]") {
          Modal.error({
            title: "3AAMSEQ",
            content: `El correo ${email} ya se encuentra registrado`,
          });
        } else {
          Modal.error({
            title: "3AAMSEQ",
            content: result.message?.message,
          });
        }
      }
      setSpin(false);
    } else {
      Modal.warn({
        title: "3AAMSEQ",
        content: "Ingresa un correo válido",
      });
    }
  };

  return (
    <Fragment>
      <Row className="subfooter">
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <img
            src={logo}
            alt="3AAMSEQ"
            title="3AAMSEQ"
            className="logo-subfooter"
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <h3>
            Suscríbete a nuestro Newsletter <br /> para más novedades y
            promociones
          </h3>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <Search
            placeholder="Ingresa tu correo electrónico"
            suffix={
              <img
                src={correo}
                alt="Enviar correo"
                title="Enviar correo"
                width={25}
              />
            }
            enterButton={spin ? "Enviando..." : "Enviar"}
            onSearch={
              spin
                ? () => {}
                : (e) => {
                    insertarEmail(e);
                  }
            }
          />
        </Col>
      </Row>
    </Fragment>
  );
}

export default SubFooterComponent;
